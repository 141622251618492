import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { includes as lodashIncludes, sortBy as lodashSortBy, truncate as lodashTruncate } from 'lodash';

import { SelectableValue } from '@grafana/data';
import { FetchError, getBackendSrv } from '@grafana/runtime';

import { getWithHeaders } from '@/api';
import {
  AggregationRecommendation,
  AggregationRule,
  Exemption,
  ExemptionsQueryResponse,
  RecommendationsConfigResponse,
} from '@/api/types';
import { useSegmentContext } from '@/hooks/context-hooks';
import { Segment } from '@/types';
import { errorAlert, successAlert } from '@/util/alert';
import { DEFAULT_SEGMENT_OPTION, paths } from '@/util/constants';
import { downloadJson } from '@/util/download';

export const QUERY_KEYS = {
  aggregatedSeries: 'aggregatedSeries',
  exemptions: 'exemptions',
  labels: 'labels',
  labelValues: 'label-values',
  metrics: 'metrics',
  rawAggDiff: 'rawAggDiff',
  rawSeries: 'rawSeries',
  recommendations: 'recommendations',
  recommendationsConfig: 'recommendationsConfig',
  rules: 'rules',
  segments: 'segments',
} as const;

const createErrorAlert = async (error: FetchError) => {
  let message = error.data.message;

  if (lodashIncludes(error.data.message, 'does not match the Etag of the rules you are trying to replace')) {
    message = 'The rules have been modified by another user. Refresh the page to continue making changes.';
  } else if (lodashIncludes(error.data.message, 'route access denied')) {
    message = 'You do not have permission to perform this action.';
  }
  errorAlert(message, error.statusText || 'Error');
};

export const useRules = () => {
  const { segmentId } = useSegmentContext();
  return useQuery([QUERY_KEYS.rules, segmentId], () =>
    getWithHeaders<AggregationRule>(paths.rules, { ...(segmentId !== 'default' ? { segment: segmentId } : {}) })
  );
};

export const useRecommendations = (verbose: boolean) => {
  const { segmentId } = useSegmentContext();
  return useQuery([QUERY_KEYS.recommendations, segmentId, verbose], () =>
    getWithHeaders<AggregationRecommendation>(paths.recommendations, {
      ...(segmentId !== 'default' ? { segment: segmentId } : {}),
      verbose,
    })
  );
};
export const useExemptions = () => {
  const { segmentId } = useSegmentContext();
  return useQuery([QUERY_KEYS.exemptions, segmentId], async () => {
    const response = await getWithHeaders<ExemptionsQueryResponse>(paths.exemptions, {
      ...(segmentId !== 'default' ? { segment: segmentId } : {}),
    });

    return lodashSortBy(response.items[0].result, (exemption) => -new Date(exemption.created_at));
  });
};

export const useCreateExemptionsMutation = () => {
  const { segmentId } = useSegmentContext();
  const queryClient = useQueryClient();

  return useMutation<unknown, FetchError, Partial<Exemption>>(
    async (exemption) => {
      await getBackendSrv().post(paths.exemptions, exemption, {
        params: { ...(segmentId !== 'default' ? { segment: segmentId } : {}) },
        showErrorAlert: false,
        showSuccessAlert: false,
      });
    },
    {
      onError: createErrorAlert,
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERY_KEYS.exemptions]);
        successAlert('Successfully created exemption.');
      },
    }
  );
};

export const useUpdateExemptionsMutation = () => {
  const { segmentId } = useSegmentContext();
  const queryClient = useQueryClient();

  return useMutation<unknown, FetchError, Partial<Exemption>>(
    async ({ disable_recommendations, id, keep_labels, metric, reason }) => {
      if (!id) {
        return Promise.reject('Id missing from exemption');
      }

      return await getBackendSrv().put(
        `${paths.exemptions}/${id}`,
        { disable_recommendations, keep_labels, metric, reason },
        {
          params: { ...(segmentId !== 'default' ? { segment: segmentId } : {}) },
          showErrorAlert: false,
          showSuccessAlert: false,
        }
      );
    },
    {
      onError: createErrorAlert,
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERY_KEYS.exemptions]);
        successAlert('Successfully updated exemption');
      },
    }
  );
};

export const useDeleteExemptionMutation = (bypassAlert = false) => {
  const { segmentId } = useSegmentContext();
  return useMutation<unknown, FetchError, string>(
    async (id) => {
      await getBackendSrv().delete(`${paths.exemptions}/${id}`, null, {
        params: { ...(segmentId !== 'default' ? { segment: segmentId } : {}) },
        showErrorAlert: false,
        showSuccessAlert: false,
      });
    },
    {
      onError: (err) => {
        if (!bypassAlert) {
          return createErrorAlert(err);
        }
        return;
      },
    }
  );
};

export const useRecommendationsConfig = () =>
  useQuery([QUERY_KEYS.recommendationsConfig], () =>
    getWithHeaders<RecommendationsConfigResponse>(paths.recommendationsConfig)
  );

export const useCheckRulesMutation = () => {
  const { segmentId } = useSegmentContext();
  return useMutation<unknown, FetchError, AggregationRule[]>(
    async (aggregationRules: AggregationRule[]) => {
      await getBackendSrv().post<AggregationRule[]>(paths.checkRules, aggregationRules, {
        params: { ...(segmentId !== 'default' ? { segment: segmentId } : {}) },
        showErrorAlert: false,
      });
    },
    {
      onError: createErrorAlert,
    }
  );
};

export const useUpdateRulesMutation = () => {
  const { segmentId } = useSegmentContext();
  const queryClient = useQueryClient();

  return useMutation<unknown, FetchError, { eTag: string; rules: AggregationRule[] }>(
    async ({ eTag, rules }) => {
      await getBackendSrv().post<AggregationRule[]>(paths.rules, rules, {
        headers: { 'If-Match': eTag },
        params: { ...(segmentId !== 'default' ? { segment: segmentId } : {}) },
        showErrorAlert: false,
      });
    },
    {
      onError: createErrorAlert,
      onSuccess: async () => {
        successAlert('Successfully updated rules.');
        await queryClient.invalidateQueries([QUERY_KEYS.rules]);
      },
    }
  );
};

export const useDownloadExemption = () => {
  const { data: exemptions } = useExemptions();

  return useCallback(() => {
    if (!exemptions) {
      return;
    }

    downloadJson(exemptions, 'exemptions.json');
  }, [exemptions]);
};

export const useSegments = () =>
  useQuery([QUERY_KEYS.segments], async () => {
    const data = await getWithHeaders<Segment>(paths.segments);
    const selectableValues: Array<SelectableValue<string>> = [DEFAULT_SEGMENT_OPTION];
    data.items.forEach((segment) => {
      selectableValues.push({
        description: lodashTruncate(segment.selector, { length: 60 }),
        label: segment.name,
        value: segment.id,
      });
    });

    return { items: data.items, selectableValues };
  });

export const useSegmentsAddMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, FetchError, { segment: Segment }>(
    async ({ segment }) => {
      await getBackendSrv().post<Segment>(paths.segments, segment, {
        showErrorAlert: false,
      });
    },
    {
      onError: createErrorAlert,
      onSuccess: async () => {
        successAlert('Successfully added segment.');
        await queryClient.invalidateQueries([QUERY_KEYS.segments]);
      },
    }
  );
};

export const usePromLabels = () =>
  useQuery([QUERY_KEYS.labels], () => getWithHeaders<{ data: string[] }>(paths.grafanaPromLabels));

export const useLabelValues = (label?: string) =>
  useQuery([QUERY_KEYS.labelValues, label], async (): Promise<string[]> => {
    if (label) {
      const data = await getWithHeaders<{ data: string[] }>(paths.getLabelValuesPath(label));
      if (data.items.length > 0) {
        return data.items[0].data || [];
      }
    }
    return [];
  });
