import React from 'react';

import { ExploreUrlState, serializeStateToUrlParam, toURLRange } from '@grafana/data';
import {
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { LinkButton } from '@grafana/ui';

type DataQueryWithExpr = DataQuery & { expr: string };

const getPrometheusExploreUrl = ({
  queries,
  range,
}: {
  queries?: DataQueryWithExpr[];
  range: SceneTimeRangeState;
}): string => {
  const urlState: ExploreUrlState = {
    datasource: (queries?.length && queries[0].datasource?.uid) || null,
    queries:
      queries?.map(({ expr, refId }, i) => {
        return { expr, refId };
      }) || [],
    range: toURLRange(range ? { from: range.from, to: range.to } : { from: 'now-1h', to: 'now' }),
  };

  const param = encodeURIComponent(serializeStateToUrlParam(urlState));

  return `/explore?left=${param}`;
};

const SceneExploreButtonRenderer = ({ model }: SceneComponentProps<SceneExploreButton>) => {
  const data = sceneGraph.getData(model).useState();
  const timeRange = sceneGraph.getTimeRange(model).useState();

  const url = getPrometheusExploreUrl({
    queries: data.data?.request?.targets as DataQueryWithExpr[],
    range: timeRange,
  });
  return (
    <LinkButton
      icon={'compass'}
      variant="secondary"
      aria-label="See more in explore"
      size={'md'}
      href={url}
      target={'_blank'}
    >
      Explore
    </LinkButton>
  );
};

interface SceneExploreButtonState extends SceneObjectState {}

export class SceneExploreButton extends SceneObjectBase<SceneExploreButtonState> {
  static Component = SceneExploreButtonRenderer;
}
