import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { FieldColorModeId, RawTimeRange } from '@grafana/data';
import {
  EmbeddedScene,
  PanelBuilders,
  QueryRunnerState,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { useStyles2 } from '@grafana/ui';

import { SceneExploreButton } from '@/components/SceneExploreButton';
import { useIdQueryModifier } from '@/hooks';
import { promQueries, USAGE_DATASOURCE } from '@/util/constants';

const getScene = (idQueryModifier: string) => {
  const queryRunnerState: QueryRunnerState = {
    datasource: {
      type: 'prometheus',
      uid: USAGE_DATASOURCE,
    },
    queries: [
      {
        expr: `${promQueries.rawSeries}${idQueryModifier} - ${promQueries.aggregatedSeries}${idQueryModifier}`,
        refId: 'A',
      },
    ],
  };
  const query: SceneQueryRunner = new SceneQueryRunner(queryRunnerState);
  const rawTimeRange: RawTimeRange = { from: 'now-7d', to: 'now' };
  const range = new SceneTimeRange(rawTimeRange as Partial<SceneTimeRangeState>);

  return new EmbeddedScene({
    $data: query,
    $timeRange: range,
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: PanelBuilders.timeseries()
            .setTitle('Time series reduced by applied rules')
            .setColor({ mode: FieldColorModeId.ContinuousBlPu })
            .setCustomFieldConfig('fillOpacity', 15)
            .setData(
              new SceneDataTransformer({
                transformations: [
                  {
                    id: 'renameByRegex',
                    options: {
                      regex: '(.*)',
                      renamePattern: 'Time series reduced by applied rules $1',
                    },
                  },
                ],
              })
            )

            .setUnit('short')
            .setHeaderActions([new SceneExploreButton({}), new SceneTimePicker({}), new SceneRefreshPicker({})])
            .build(),
          height: 300,
          width: '100%',
        }),
      ],
    }),
  });
};

const getStyles = () => ({
  sceneBackground: css({
    section: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  }),
});

export const SceneRawVsAggregated = () => {
  const styles = useStyles2(getStyles);
  const idQueryModifier = useIdQueryModifier();
  const Scene = useMemo(() => getScene(idQueryModifier), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <span className={styles.sceneBackground}>
      <Scene.Component model={Scene} />
    </span>
  );
};
